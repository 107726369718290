<template>
    <v-navigation-drawer
      v-model="toogle_filters"
      right
      fixed
      width="500"
      temporary
      :overlay-opacity="0.1"
      class="white--text content-scroll"
    >
      <v-list-item class="py-0 grey lighten-5">
        <v-list-item-content>
          <v-list-item-title class="text-h4 primary--text font-weight-bold">
            <v-icon class="mt-n1" left>mdi-tune</v-icon>
            Filtros
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon @click="toogle_filters = !toogle_filters">
             <v-icon size="20" color="blue-grey">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-expansion-panels
        v-model="item_filters"
        accordion 
        multiple 
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Usuarios</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectedUsers"
              class="input-filters-advanced"
              :items="filtros.usuarios"
              outlined
              multiple
              chips
              placeholder="Seleccione"
              deletable-chips
              small-chips
              attach
              clearable
              hide-details
              :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
            >
              <template v-slot:item="{item}">
                <v-icon left color="blue-grey" size="20">mdi-account</v-icon>
                <span class="text-uppercase primary--text" v-text="item" />
              </template>
            </v-autocomplete>
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
         <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Estatus</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="status" 
              class="mt-0"               
              label="Éxito"
              color="icono"
              hide-details
              value="success"
            />
            <v-checkbox 
              v-model="status"               
              label="Error"
              color="icono"
              hide-details
              value="error"
            />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Codigo</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectedCod"
              class="input-filters-advanced"
              :items="filtros.codigo"
              outlined
              placeholder="Seleccione"
              attach
              clearable
              deletable-chips
              small-chips
              hide-details
              :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
            >
              <template v-slot:item="{item}">
                <v-icon left color="blue-grey" size="20">mdi-adjust</v-icon>
                <span class="text-uppercase primary--text" v-text="item" />
              </template>
            </v-autocomplete>
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Tabla Base de Datos</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectedBD"
              class="input-filters-advanced"
              :items="filtros.tablas"
              outlined
              placeholder="Seleccione"
              attach
              clearable
              deletable-chips
              small-chips
              multiple
              hide-details
              :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
            >
              <template v-slot:item="{item}">
                <v-icon left color="blue-grey" size="20">mdi-database</v-icon>
                <span class="text-uppercase primary--text" v-text="item" />
              </template>
            </v-autocomplete>
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels> 
      <template #append>
        <v-row class="grey lighten-5">
          <v-col cols="10" class="d-flex justify-center align-center px-8 pt-4 pb-8 mx-auto">
            <v-btn class="mx-3 px-8" outlined tile @click="resetear">
              Resetear
            </v-btn>
            <v-btn class="px-8" color="primary" tile @click="aplicarFiltros">
              <v-icon left>mdi-check</v-icon> 
              Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <!-- <div class="pt-12" /> -->
    </v-navigation-drawer>
</template>
<script>

export default {
  name:'AvancedFiltersLogs',
  props:{
    value: Boolean,
    filtros:{
      type: Object,
      default: () =>({}),
    },
  },
  data () {
    return {
      item_filters: [],
      toogle_filters: this.value,
      status: '',
      selectedUsers: [],
      selectedBD: '',
      selectedCod: '',
    }
  },
  watch: {
    toogle_filters (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.toogle_filters = val
    },
  },
  methods: {
    aplicarFiltros () {
      this.toogle_filters = false;
      this.$emit('filtrarDatos', 
        {
          status: this.status ?? '',
          usuario: this.selectedUsers,
          tabla: this.selectedBD,
          codigo: this.selectedCod,
        }
      )
    },
    resetear () {
      this.status = ''
      this.selectedUsers = []
      this.selectedBD = ''
      this.selectedCod = ''
    },
  },
}
</script>
<style lang="sass">
.input-filters-advanced .v-input__control fieldset
  background-color: #fafafa !important
</style>